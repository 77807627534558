<template>
  <v-dialog
    v-model="isPopupVisible"
    persistent
    max-width="600px"
  >
    <v-card>
      <div
        v-if="contact"
        class="messageComposerWrapper"
      >
        <div class="messageComposerHeader">
          <!-- <h1 class="popupHeaderLabel">
                Messaging
            </h1> -->
          <h1>{{ contact.firstName }} {{ contact.lastName }}</h1>
          <h2>{{ contact.cellNumber }}</h2>
          <button
            class="closeBtn"
            @click="_hidePopup"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </button>
        </div>
        <div class="messageComposerOuterWrapper">
          <textarea
            v-model="messageText"
            cols="30"
            rows="7"
          />
          <button
            class="sendBtn"
            :disabled="isLoaderActive || !messageText.length"
            @click="_sendMessage"
          >
            <v-icon>
              mdi-send
            </v-icon>
          </button>
        </div>
        <p class="errorText">
          {{ isSubmitted && !messageText.length? 'No Message': '' }}
        </p>
        <div
          v-if="isLoaderActive"
          class="messageLoader"
        >
          <div class="loader" />
        </div>
        <div
          v-if="!isLoaderActive && messages.length"
          class="messageViewer"
        >
          <div
            v-for="(message, i) in messages"
            :key="i"
            class="messageWrapper"
          >
            <div
              v-if="!message._formattedData.isResponse"
              class="sentMessageWrapper"
            >
              <div class="sentMessage">
                <div class="messageHeader">
                  Sent at {{ message._formattedData.dateTime }}
                </div>
                {{ message.Message }}
              </div>
            </div>
            <div
              v-if="message._formattedData.isResponse"
              class="recievedMessageWrapper"
            >
              <div class="recievedMessage">
                <div class="messageHeader">
                  Recieved at {{ message._formattedData.dateTime }}
                </div>
                {{ message.Message }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import EventEmitter from '../../../utils/event-emitter'
  // Main JS (in UMD format)
  import moment from 'moment'

  export default {
    name: 'MessagingPopup',
    props: {},
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      snackbar: false,
      notification: 'Unknown',
      isPopupVisible: false,
      contact: null,
      isLoaderActive: false,
      messages: [],
      messageText: '',
      isSubmitted: false,
      intervalRef: null,
    }),
    computed: {},
    watch: {
      isPopupVisible (val) {
        if (!val) {
          this._clearInterval()
        } else {
          this._initiateMessageCheckInBackground()
        }
      },
    },

    async created () {
      this.usermail = localStorage.getItem('username')
      EventEmitter.subscribe('show-messaging-popup', (contact) => {
        this.contact = contact
        this.isPopupVisible = true
        this.isLoaderActive = true
        this._loadMessages()
      })
    },

    methods: {
      _clearInterval () {
        if (this.intervalRef) {
          clearInterval(this.intervalRef)
          this.intervalRef = null
        }
      },
      _initiateMessageCheckInBackground () {
        if (!this.intervalRef) {
          this.intervalRef = setInterval(() => {
            this._loadMessages(false)
          }, [5000])
        }
      },
      async _loadMessages (shouldShowLoader = true) {
        const payload = {
          CellNumber: this.contact.cellNumber,
          GroupID: this.contact.groupId,
          Status: 'delivered', // status = delivery
          MessageType: '3', // 1=sms sent, 2=responses, 3=both
        }
        try {
          const { data } = await this.httpConnector.makeRequest('post', `${this.apiEndpoint}GetContactSMSHistory?code=${this.apiCode}`, payload)
          this.messages = this._formatMessages(data)
        } catch (error) {
          console.log('error :>> ', error)
        }
        if (shouldShowLoader) {
          this.isLoaderActive = false
        }
      },
      _formatMessages (messages) {
        return messages.map(message => ({
          ...message,
          _formattedData: {
            isResponse: message.Sender === message.CellFrom,
            dateTime: moment(message.CreateDate).format('hh:mm a MM/DD/YYYY'),
          },
        })).reverse()
      },
      async _sendMessage () {
        this.isSubmitted = true
        if (this.messageText.trim().length) {
          this.isLoaderActive = true
          try {
            const payload = {
              ToPhoneNumbers: this.contact.cellNumber,
              Message: this.messageText,
              GroupID: this.contact.groupId,
              // CampaignName: this.field.name,
              Sender: this.usermail,
              // MediaURL: mediaUrl,
            }
            await this.httpConnector.makeRequest('post', `${this.apiEndpoint}TwilioSendBulkSMS?code=${this.apiCode}`, payload)
            this.isSubmitted = false
            this.messageText = ''
            this._loadMessages()
          } catch (error) {
            console.log('error :>> ', error)
          }
          this.isLoaderActive = false
        }
      },
      _hidePopup () {
        this._reset()
        this.isPopupVisible = false
      },
      _reset () {
        this.isPopupVisible = false
        this.contact = null
        this.isLoaderActive = false
        this.messages = []
        this.messageText = ''
      },
    },
  }
</script>
<style lang="scss" scoped>
.messageComposerWrapper {
  min-height: 800px;
  width: 600px;
  .messageComposerHeader {
    width: 100%;
    height: 150px;
    background-color: #db8400;
    padding: 0rem 6rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    .closeBtn {
        position: absolute;
        top: 3rem;
        right: 3rem;
        width: 30px;
      height: 30px;
      border-radius: 30px;
      background-color: rgb(211, 0, 0);
      * {
        color: white;
      }
    }
  }

  .messageComposerOuterWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    textarea {
      background-color: rgb(216, 216, 216);
      width: 85%;
      margin-right: 15px;
      border: 1px solid rgb(216, 216, 216);
      padding: 5px;
      outline: none;
    }
    .sendBtn {
      width: 60px;
      height: 60px;
      border-radius: 60px;
      background-color: green;
      * {
        color: white;
      }
      &:disabled {
        background-color: grey;
        cursor: not-allowed;
      }
    }
  }

  .errorText {
    color: red;
    height: 15px;
    padding-left: 15px;
  }

  .messageViewer {
    margin-top: 60px;
    padding: 2rem 15px;
    overflow-y: scroll;
    max-height: 400px;
    background-color: rgb(243, 241, 241);
    .messageHeader {
      font-size: 0.8rem;
      font-weight: 600;
      padding-bottom: 15px;
    }
    .sentMessageWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 15px 15px 15px 0;
      .sentMessage {
        min-width: 100px;
        padding: 10px 15px;
        background-color: rgb(207, 207, 207);
        border-radius: 5px;
      }
    }
    .recievedMessageWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 15px 0 15px 15px;
      .recievedMessage {
        min-width: 100px;
        padding: 10px 15px;
        background-color: rgb(37, 37, 37);
        border-radius: 5px;
        color: white;
        text-align: right;
      }
    }
  }
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  .loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(240, 164, 0, 0.2);
    border-right: 1.1em solid rgba(240, 164, 0, 0.2);
    border-bottom: 1.1em solid rgba(240, 164, 0, 0.2);
    border-left: 1.1em solid #f0a400;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
</style>
